import { Component } from 'react';
import { connect } from 'react-redux'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Select from 'react-select';
import Form from 'react-bootstrap/Form';


import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { MODIFY_INTERFACE_PARAM } from '../redux/actions';
import { apiRequest, apiRequestWithFiles, getAttachmentForSale, getInvoiceForSale, getOfferForSale } from '../redux/actionsAPI';
import {LABEL_QNOTE, DEFAULT_VALUE_LONG_NOTE} from "../econf/cnf"

import moment from "moment"
import { dynamicSortMultiple, oValues, productValueLabel, valueLabel } from './_tools';

class SaleEditModal extends Component {
    numFields = [["agreedPrice", "HTVA"]]
    textFields = [["oneTimeProduct", "Product customisation"], ["note",LABEL_QNOTE], ["invoiceReceival", "To whom the invoice is addressed (pdf)"], [ "invoiceRef", "Reference"], [ "invoiceCRef", "Client reference (PO)"], ['invoiceMemo', 'Accounting note']]
    uDate = new Date ()
    state =  Object.assign({revertedBy:[], revertedOf:-1, deleteClientAttachment:false, deleteAttachment:false, wereChanges:false, taskID: null, selectedFile: null, selectedClientFile: null, hasFile: null, hasClientFile: null, isHidden:false, invoiceMissingField:null, initialBy: null, products: [], contact:{value:0, label: '----'}, contactDetails:null, contactCompany: null, contactCompanySel:{value:0, label: '----'}, 
                            user:{value:0, label: '----'}, longNote: null, invoiceChecked: false, invoiceSent: false, invoicePaid: false, cashPaid:false, willCashPaid: false, product:{value:0, label: '----'},
                            transactionDate: moment().toDate(), transactionDateT: moment().toDate().getTime(), invoiceDate: null, invoiceDateT: null, membershipStartDate:null, membershipStartDateT: null}, ...this.textFields.map ((el) => ({ [el[0]]:"" })).concat (...this.numFields.map ((el) => ({ [el[0]]:"" })))) 
    
    productsList = []
    contactsList = []
    companiesList = []
    usersList = []
    
    componentDidMount() {
        var initialBy = this.props.profile.id
        if (this.props.interface.editingSaleId > 0)
        {
            var cs = {}
            if (this.props.interface.editingSaleId in this.props.sales)
            {
                var el = this.props.sales[this.props.interface.editingSaleId]
                for (var tf of this.textFields)
                    cs[tf[0]] = el[tf[0]] ? el[tf[0]]: ""
                for (var tf of this.numFields)
                    cs[tf[0]] = el[tf[0]] ? el[tf[0]]: ""
                for (var f of ['isHidden', 'lastEditBy', 'invoiceChecked', 'invoicePaid', 'invoiceSent', 'cashPaid', 'willCashPaid', 'products', 'taskID', 'horusTransactionId', 'longNote'])
                    cs[f] = el[f]
                cs['by'] = valueLabel (this.props.users[el['idBy']], 'id', 'fullName')
                for (f of ['transactionDate', 'invoiceDate', 'membershipStartDate'])
                {
                    var v = el[f] ? moment (el[f], 'DD-MM-YYYY').toDate() : null
                    cs[f] = v
                    cs[f+'T'] = v ? v.getTime() : v
                }
                cs.hasFile = el.attachmentName
                cs.hasClientFile = el.clientAttachmentName
                cs.revertedBy = el.revertedBy
                cs.revertedOf = el.revertedOf
                
                for (var pp of cs['products'])
                {
                    if (pp.idProduct in this.props.productConfig.productsDic)
                    {
                        pp['productD'] = this.props.productConfig.productsDic[pp.idProduct]
                        pp['product'] = productValueLabel (pp.idProduct, this.props.productConfig)
                    }

                }

                this.fillContactDetails (el.idContact, true)
                const ccomp = this.fillCompanyDetails ((el.idCompany > 0) ? el.idCompany : 0)
                initialBy = el.idBy

                for (var ff of [['invoiceRef', 'invoice reference'], ['invoiceCRef', 'client invoice reference'], ['idCompany', 'company'], ['invoiceDate', 'invoice date'], ['agreedPrice', 'product prices'], ['byInitials', 'seller'] ])
                {
                    if (!el[ff[0]])
                    {
                        cs.invoiceMissingField = ff[1]
                        break
                    }
                }
                if (!ccomp)
                    cs.invoiceMissingField = "Company"
                else
                {
                    for (var cf of [["name", "name"], ["address", "address"], ["zipCode", "zip code"], ["city", "city"], ["country", "country"]])
                        if ((!cs.invoiceMissingField) && (!ccomp[cf[0]]))
                            cs.invoiceMissingField = "company " + cf[1]
                    if ((!cs.invoiceMissingField) && (!ccomp["vat"]) && (!ccomp["isOutOfEU"]) && (!ccomp["inBelgiumButWithoutVAT"]))
                        cs.invoiceMissingField = "company VAT"
                }
                
                if ((!cs.invoiceMissingField) && (el.products.length === 0) && ((el.oneTimeProduct.length < 2) || (!el.isHidden)))
                    cs.invoiceMissingField = "products"
                /*
                if (!cs.invoiceMissingField)
                    for (var pp of el.products)
                        for (var pf of [["description", "descriptions"]])
                            if (!pp.productD[pf[0]])
                            {
                                cs.invoiceMissingField = "product " + pf[1]
                                break
                            }
                */
                
            }
            this.setState (cs)
        }
        else
        {    
            this.setState ({ "invoiceReceival": "Attn. Accounting Department", "longNote": DEFAULT_VALUE_LONG_NOTE})
            if (this.props.interface.selectedContactId) {
                var cc = this.fillContactDetails (this.props.interface.selectedContactId)
                this.setState ({contact: cc})
                this.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "selectedContactId", "paramValue": null});
            }
            if (this.props.interface.selectedCompanyId) {
                this.fillCompanyDetails (this.props.interface.selectedCompanyId)
                this.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "selectedCompanyId", "paramValue": null});
            }
        }
        this.setState ({"initialBy":initialBy})
    }

    handleInputChange = e => {
        this.setState({ [e.target.name]: e.target.value, wereChanges:true });
    };

    handleSelectChange = (e, inp) => {
        this.setState({ [inp.name]: e, wereChanges:true });
        if (inp.name === 'contact')
            this.fillContactDetails (e.value)
        if (inp.name === 'contactCompanySel')
            this.fillCompanyDetails (e.value)
    };

    handleCBChange = e => {
        this.setState({ [e.target.name]: !this.state[e.target.name], wereChanges:true });
    };

    fillContactDetails (nID) {
        if (nID in this.props.contactConfig.contactsDic)
        {
            var cc = this.props.contactConfig.contactsDic[nID]
            this.fillCompanyDetails (cc.idCompany)
            this.setState ({contactDetails: cc, contact:{value:nID, label:cc.fullName}})
            return {value:cc.id, label: cc.fullName + " ( " + ((cc.idCompany > 0) ? this.props.contactConfig.companiesDic[cc.idCompany].name : "") + ")"}
        }
        this.setState ({contactDetails: null})
        return {value:0, label: '----'}
    }

    fillCompanyDetails (nID) {
        if (nID in this.props.contactConfig.companiesDic)
        {
            var ccomp = this.props.contactConfig.companiesDic[nID]
            this.setState ({contactCompany: ccomp, contactCompanySel:{value:ccomp.id, label: ccomp.name}})
            return ccomp
        }
        this.setState ({contactCompany: null, contactCompanySel:{value:0, label: '----'}})
        return null
    }

    updateAgreedPrice (products) {
        var ap = 0
        for (var pp of products) {
            if (pp.agreedPrice)
                ap += pp.quantity * pp.agreedPrice
        }
        this.setState ({agreedPrice:ap})
    }

    render () {
        var that = this
        this.productsList = oValues (this.props.productConfig.productsDic).sort (dynamicSortMultiple('*fullName')).map (function (el) {
            return {value:el.id, label: el.fullName}
        })
        this.contactsList = oValues (this.props.contactConfig.contactsDic).sort (dynamicSortMultiple("*user__last_name", "*user__first_name"))
        this.companiesList = oValues (this.props.contactConfig.companiesDic).sort (dynamicSortMultiple('*name'))
        this.usersList = oValues (this.props.users).filter(function (el) {
            return el.enabled || (el.id === that.state.initialBy)
        }).sort(dynamicSortMultiple ('*fullName')).map (function (el) {
            return {value:el.id, label: el.fullName}
        })

        var invoiceCaption = (this.state.agreedPrice < 0) ? "Credit note":"Invoice"

        var selOptions = {
            'by': that.usersList,
            'product': that.productsList
        }
        return (
            <Modal size='xl' className='noPrint' scrollable={true} show={true} onHide={(e)=>{that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingSaleId", "paramValue": null})}}>
                <Modal.Header closeButton>
                    <Modal.Title>{(that.props.interface.editingSaleId > 0) ? ("Edit transaction " + that.props.interface.editingSaleId):"Create transaction"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form method="post" onSubmit={(e)=>{e.preventDefault}}>
                        <Row>
                            <Col md={4}>
                                <div className="control">
                                    <label htmlFor="pDate" className="form-label">Transaction date</label>
                                    <DatePicker 
                                        selected={that.state.transactionDate} 
                                        dateFormat="dd-MM-yyyy"
                                        calendarStartDay={1}
                                        onChange={(date) => { that.setState ({transactionDate:date, transactionDateT: date.getTime(), wereChanges:true}); }}/>
                                </div>
                                <div className="control membershipRelated">
                                    <label htmlFor="pDate" className="form-label">Membership start date</label>
                                    <DatePicker 
                                        selected={that.state.membershipStartDate} 
                                        dateFormat="dd-MM-yyyy"
                                        calendarStartDay={1}
                                        onChange={(date) => { that.setState ({membershipStartDate:date, membershipStartDateT: date.getTime(), wereChanges:true}); }}/>
                                </div>

                                {[['by', 'By']].map (function (sel, ic) {
                                    return <div className="mb-3" key={"sel_" + ic}>
                                                <label className="form-label">{sel[1]}</label>
                                                <div>
                                                    <Select 
                                                        menuPosition="fixed"
                                                        name={sel[0]}
                                                        id={sel[0]}
                                                        onChange={that.handleSelectChange}
                                                        value={that.state[sel[0]]}
                                                        options={[{value:0, label:"----"}].concat (selOptions[sel[0]])}
                                                    />
                                                </div>
                                            </div>

                                })}

                                {that.props.profile.isExAdmin ? <div className="control">
                                        <input
                                            className="form-check-input"
                                            name="isHidden"
                                            onChange={that.handleCBChange}
                                            checked={that.state['isHidden']}
                                            type="checkbox"
                                        />
                                        <label htmlFor="isHidden" className="form-check-label ms-2">Hidden for users</label>
                                    </div>:null}

                                <div>
                                    <Button style={{zIndex:10, position:"relative", marginTop:"-5px"}} variant='link' className="float-end pt-0 pb-0" onClick={(e)=>{ var cs = [...that.state.products]; cs.push ({"id":-1, "product": {"value":0, "label": '----'}, "agreedPrice":null, "quantity":1}); that.setState ({products: cs});    }}><i className={"fs-4 bi-plus-circle"}></i></Button>
                                    <label className="form-label">Products</label>
                                </div>
                                {that.state.products.map (function (d, ic) {
                                    return <div key={"productLine" + ic} className='productLine mb-3'>
                                        
                                        <div>
                                            <Button style={{zIndex:10, position:"relative"}} variant='link' className="float-end pt-0 pb-0" onClick={(e)=>{ var cs = [...that.state.products]; cs.splice(ic, 1); that.updateAgreedPrice (cs); that.setState ({products: cs});    }}><i className={"fs-4 bi-dash-circle"}></i></Button>
                                            <div className='selectField' >
                                                <Select 
                                                    menuPosition="fixed"
                                                    name="product"
                                                    onChange={(e, inp) => { var pr = that.state.products; pr[ic]["product"] = e; that.setState({ "products": pr });}}
                                                    value={productValueLabel (d.product ? d.product.value : d.idProduct, that.props.productConfig)}
                                                    options={[{value:0, label:"----"}].concat (selOptions["product"])}
                                                />
                                            </div>
                                            <div className='priceField'>
                                                <input
                                                    className="form-control"
                                                    name={"price"}
                                                    onChange={e => { var pr = that.state.products; pr[ic]["agreedPrice"] = e.target.value; that.updateAgreedPrice (pr);  that.setState({ "products": pr }); }}
                                                    value={d["agreedPrice"]}
                                                    type="number"
                                                    placeholder='Price'
                                                />
                                            </div>
                                            
                                        </div>
                                    </div>
                                })}

                                <div className="mb-3">
                                    {this.numFields.map (function (fn, ic) {
                                        return <div className="control" key={"tf" + ic}>
                                        <label htmlFor={fn[0]} className="form-label">{fn[1]}</label>
                                        <input
                                            id={fn[0]}
                                            className="form-control"
                                            name={fn[0]}
                                            onChange={that.handleInputChange}
                                            value={that.state[fn[0]]}
                                            type="number"
                                            disabled={(!that.props.profile.isExAdmin) || (that.state.products.length > 0) || (!that.state.isHidden)}
                                        />
                                    </div>
                                    })}
                                    {this.textFields.map (function (fn, ic) {
                                        return <div className="control" key={"tf" + ic}>
                                        <label htmlFor={fn[0]} className="form-label">{fn[1]}</label>
                                        <input
                                            id={fn[0]}
                                            className="form-control"
                                            name={fn[0]}
                                            onChange={that.handleInputChange}
                                            value={that.state[fn[0]]}
                                            type="text"
                                            disabled={(fn[0]==='invoiceRef') && (!that.props.profile.isExAdmin)}
                                        />
                                    </div>
                                    })}
                                    {that.state.revertedOf && (that.state.revertedOf > 0) ? <div>Rev. of {that.state.revertedOf}</div>:null}
                                    {that.state.revertedBy && (that.state.revertedBy.length > 0) ? <div>Rev. by {that.state.revertedBy}</div>:null}     

                                
                                    {[['invoiceSent', "Sent"], ['invoicePaid', 'Paid'], ['invoiceChecked', 'Checked']].map (function (fn, ic) {
                                        return <div className={"control chI" + fn[0]} key={"cb" + ic}>
                                        <input
                                            id={fn[0]}
                                            className={"form-check-input"}
                                            name={fn[0]}
                                            onChange={that.handleCBChange}
                                            checked={that.state[fn[0]]}
                                            type="checkbox"
                                            disabled={(!that.props.profile.isExAdmin)}
                                        />
                                        <label htmlFor={fn[0]} className="form-check-label ms-2">{fn[1]}</label>
                                    </div>
                                    })}
                                    {[['cashPaid', 'Paid in cash'], ['willCashPaid', 'Will be paid at receival']].map (function (fn, ic) {
                                        return <div className={"control chI" + fn[0]} key={"cb" + ic}>
                                        <input
                                            id={fn[0]}
                                            className={"form-check-input"}
                                            name={fn[0]}
                                            onChange={that.handleCBChange}
                                            checked={that.state[fn[0]]}
                                            type="checkbox"
                                            disabled={(fn[0]==='invoiceRef') && (!that.props.profile.isExAdmin)}
                                        />
                                        <label htmlFor={fn[0]} className="form-check-label ms-2">{fn[1]}</label>
                                    </div>
                                    })}
                                </div> 

                                <div className="control">
                                    <label htmlFor="invoiceDate" className="form-label">{invoiceCaption} date</label>
                                    <DatePicker 
                                        selected={that.state.invoiceDate} 
                                        dateFormat="dd-MM-yyyy"
                                        calendarStartDay={1}
                                        onChange={(date) => { that.setState ({invoiceDate:date, invoiceDateT: date.getTime(), wereChanges:true}); }}/>
                                </div>
                                {that.state.lastEditBy ? <div className="control mt-3">
                                    <label className="form-label">Last edit by: {that.state.lastEditBy}</label>
                                </div>:null}
                                
                                
                            </Col>
                            <Col md={4}>
                                <label className="form-label"><b>Contact</b></label>
                                <div>
                                    <Button style={{zIndex:10, position:"relative"}} variant='link' className="float-end pt-0 pb-0" onClick={(e)=>{that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingContactId", "paramValue": 0})}}><i className={"fs-4 bi-plus-circle"}></i></Button>
                                    <Select 
                                        menuPosition="fixed"
                                        name="contact"
                                        id="contact"
                                        onChange={that.handleSelectChange}
                                        value={that.state.contact}
                                        options={[{value:0, label:"----"}].concat (that.contactsList.map (function (el) {
                                            return {value:el.id, label: el.fullName + " ( " + ((el.idCompany > 0) ? (that.props.contactConfig.companiesDic[el.idCompany].shortName ? that.props.contactConfig.companiesDic[el.idCompany].shortName:that.props.contactConfig.companiesDic[el.idCompany].name) : "") + ")"}
                                        }))}
                                    />
                                </div>
                                { this.state.contactDetails ? <div className='mt-3'>
                                    {[['Last name', 'user__last_name'], ['First name', 'user__first_name'], ['Job title', 'jobTitle'], ['Phone', 'phone'], ['GSM', 'gsm'], ['E-mail', 'user__email']].map (function (el, ic) {
                                        if (!that.state.contactDetails[el[1]])
                                            return null
                                        return <div key={"cDet" + el[1]}>
                                            <label className="form-label">{el[0]} : {that.state.contactDetails[el[1]]}</label>
                                        </div>
                                    })}</div>: null}
                                <div className='mt-3'>
                                    <label className="form-label"><b>Company:</b></label>
                                    <Select 
                                        menuPosition="fixed"
                                        name="contactCompanySel"
                                        id="contactCompanySel"
                                        onChange={that.handleSelectChange}
                                        value={that.state.contactCompanySel}
                                        options={[{value:0, label:"----"}].concat (that.companiesList.map (function (el) {
                                            return {value:el.id, label: el.name}
                                        }))}
                                    />
                                    { this.state.contactCompany ? <div className='mt-3'>
                                        {[['Company name', 'name'], ['VAT', 'vat'], ['Address', 'adress'], ['City', 'city'], ['ZIP', 'zipCode'], ['Country', 'country'], ['Website', 'website']].map (function (el, ic) {
                                            if (!that.state.contactCompany[el[1]])
                                                return null
                                            return <div key={"compDet" + el[1]}>
                                                <label className="form-label">{el[0]} : {that.state.contactCompany[el[1]]}</label>
                                            </div>
                                        })}
                                        <Button className="float-end btn-primary" onClick={(e)=>{that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingCompanyId", "paramValue": this.state.contactCompany.id})}}>Edit company details</Button>
                                    </div>:null}
                                </div>

                            </Col>
                            <Col md={4}>
                                <div>
                                    <div className="control">
                                        <label htmlFor="attachment" className="form-label">{that.state.hasFile ? "Replace ":""} Attachment</label>
                                        <Form.Control type="file" name="attachment" onChange={(e) => {that.setState({ selectedFile: e.target.files[0]});}} accept="*" />
                                    </div>
                                    {that.state.hasFile ? <div>
                                        <span className='d-inline-block align-top pt-1'>Current attachment:</span> <Button className='py-0 align-top' variant="link" onClick={(e)=>{that.props.dispatch (getAttachmentForSale (that.props.interface.editingSaleId, that.state.hasFile))}}>{that.state.hasFile}</Button>
                                        <div className="control">
                                                <input
                                                    className="form-check-input"
                                                    name="deleteAttachment"
                                                    onChange={that.handleCBChange}
                                                    checked={that.state['deleteAttachment']}
                                                    type="checkbox"
                                                />
                                                <label htmlFor="deleteAttachment" className="form-check-label ms-2">Remove attachment</label>
                                            </div>
                                    </div>:null}
                                    
                                </div>
                                {that.state.taskID ? <div className='mt-3'>
                                    <Button className="btn-secondary" onClick={(e)=>{e.stopPropagation(); that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingTaskId", "paramValue": that.state.taskID})}}>Browse historic task</Button>
                                </div>:null}
                                {[["longNote", "Note"]].map (function (fn, ic) {
                                        return <div className="control mt-3" key={"ltf" + ic}>
                                        <label htmlFor={fn[0]} className="form-label">{fn[1]}</label>
                                        <textarea
                                            id={fn[0]}
                                            className="form-control"
                                            name={fn[0]}
                                            onChange={that.handleInputChange}
                                            value={that.state[fn[0]]}
                                            rows={20}
                                        />
                                    </div>
                                })}


<div>
                                    <div className="control">
                                        <label htmlFor="clientattachment" className="form-label">{that.state.hasClientFile ? "Replace ":""} Client Attachment <b>(will be attached to all e-mails!)</b></label>
                                        <Form.Control type="file" name="clientAttachment" onChange={(e) => {that.setState({ selectedClientFile: e.target.files[0]});}} accept="*" />
                                    </div>
                                    {that.state.hasClientFile ? 
                                        <div>
                                            <span className='d-inline-block align-top pt-1'>Current client attachment:</span> <Button className='py-0 align-top' variant="link" onClick={(e)=>{that.props.dispatch (getAttachmentForSale (that.props.interface.editingSaleId, that.state.hasClientFile, 'client'))}}>{that.state.hasClientFile}</Button>
                                            <div className="control">
                                                <input
                                                    className="form-check-input"
                                                    name="deleteClientAttachment"
                                                    onChange={that.handleCBChange}
                                                    checked={that.state['deleteClientAttachment']}
                                                    type="checkbox"
                                                />
                                                <label htmlFor="deleteClientAttachment" className="form-check-label ms-2">Remove client attachment</label>
                                            </div>
                                        </div>:null
                                    }
                                    
                                </div>
                                
                            </Col>
                        </Row>   
                    </form>    
                </Modal.Body>
                <Modal.Footer>
                    { ((this.props.profile.isAdmin || this.props.profile.isSales) && (that.props.interface.editingSaleId) && (!this.state.wereChanges)) ? 
                        (that.state.invoiceMissingField ? <span className='me-auto highlight'>Fill in {that.state.invoiceMissingField} and update to see the invoice</span>:
                            that.state.horusTransactionId ?
                                <Button variant="secondary" className='me-auto' onClick={(e)=>{that.props.dispatch (getInvoiceForSale (that.props.interface.editingSaleId, "invoice_" + that.props.interface.editingSaleId + '.pdf'))}}>
                                    {invoiceCaption} download
                                </Button>:
                                <Button variant="secondary" className='me-auto' onClick={(e)=>{that.props.dispatch (getInvoiceForSale (that.props.interface.editingSaleId, "preview_" + that.props.interface.editingSaleId + '.pdf'))}}>
                                    {invoiceCaption} preview
                                </Button>):null}
                    { ((this.props.profile.isAdmin || this.props.profile.isSales) && (that.props.interface.editingSaleId) && (!this.state.wereChanges)) ? 
                        (that.state.invoiceMissingField ? <span className='me-auto highlight'>Fill in {that.state.invoiceMissingField} and update to see the invoice</span>:
                            that.state.horusTransactionId ?
                                null:
                                <Button variant="secondary" className='me-auto offerBtn' onClick={(e)=>{that.props.dispatch (getOfferForSale (that.props.interface.editingSaleId, "offer_" + that.props.interface.editingSaleId + '.pdf'))}}>
                                    Offer download
                                </Button>):null}
                            
                            
                    { ((this.props.profile.isAdmin || this.props.profile.isSales || this.props.profile.hasMiniDashboard) && (that.props.interface.editingSaleId) && (!this.state.wereChanges)) ? 
                        (that.state.invoiceMissingField ? null:
                            ( that.state.horusTransactionId ? <span>Accounting invoice created</span>:<Button variant="secondary" className='me-auto' onClick={(e)=>{ if (confirm("Are you sure you want to create the accounting invoice? No changes will be possible afterwards")) that.props.dispatch (apiRequest({'endpoint': 'createHorusSale', "saleId": that.props.interface.editingSaleId}, {type:MODIFY_INTERFACE_PARAM, "paramName": "editingSaleId", "paramValue": null}))  }}>
                                {that.state.agreedPrice < 0 ? "Create an accounting credit note and send to the client" : "Create an accounting invoice and send to the client"}
                            </Button>)):null}
                    
                    { that.props.profile.isExAdmin && that.state.horusTransactionId && (that.props.interface.editingSaleId) && (!this.state.wereChanges) ?  <Button variant="secondary" className='float-start me-3' onClick={(e)=>{ if (confirm("Are you sure you want to recreate the PDF?")) { that.props.dispatch (apiRequest({'endpoint': 'forceInvoiceRegeneration', "saleId": that.props.interface.editingSaleId}));  }}}>
                            Force PDF recreation
                        </Button>:null}
                    { that.props.profile.isExAdmin && that.state.horusTransactionId && (that.props.interface.editingSaleId) && (!this.state.wereChanges) ?  <Button variant="secondary" className='float-start me-3' onClick={(e)=>{ if (confirm("Are you sure you want to create a revert transaction? Please double check the parameters of a new one")) { that.props.dispatch (apiRequest({'endpoint': 'revertSale', "saleId": that.props.interface.editingSaleId})); that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingSaleId", "paramValue": null}) }}}>
                            Create a revert transaction
                        </Button>:null}
                    { that.props.profile.isExAdmin && (!that.state.invoiceSent) && (!that.state.horusTransactionId) && (that.props.interface.editingSaleId) && (!this.state.wereChanges) ?  <Button variant="secondary" className='float-start me-3' onClick={(e)=>{ if (confirm("Are you sure you want to delete this sale? This will completely remove it")) that.props.dispatch (apiRequest({'endpoint': 'deleteSale', "saleId": that.props.interface.editingSaleId}, {type:MODIFY_INTERFACE_PARAM, "paramName": "editingSaleId", "paramValue": null})) }}>
                            Delete transaction
                        </Button>:null}
                    <Button variant="secondary" onClick={(e)=>{that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingSaleId", "paramValue": null})}}>
                        Close
                    </Button>
                    {(this.props.profile.isAdmin || (this.props.profile.hasMiniDashboard) || (this.props.profile.id === this.state.initialBy)) ? <Button variant="primary" onClick={(e)=>{ that.props.dispatch (apiRequestWithFiles({'endpoint': 'editSale', "saleId": that.props.interface.editingSaleId, "newState":that.state}, {"file": that.state.selectedFile, 'clientAttachment': that.state.selectedClientFile}, {type:MODIFY_INTERFACE_PARAM, "paramName": "editingSaleId", "paramValue": null})) }}>
                            Update
                        </Button>:null}
                </Modal.Footer>
            </Modal>
        );
    }
}

function mapStateToProps(state) {
    return {
        interface: state.interfaceParams,
        sales: state.data.sales,
        productConfig: state.data.productConfig,
        contactConfig: state.data.contactConfig,
        users: state.data.users,
        profile: state.data.profile
    }
}

export default connect(mapStateToProps)(SaleEditModal)