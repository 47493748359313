import Cookies from 'universal-cookie'

import { combineReducers } from 'redux'
import {
    LOADING,
    SET_NOTIFICATION,
    MODIFY_INTERFACE_PARAM
} from '../actions'

import {
    LOGGED_IN,
    LOGIN_FAILED,
    LOG_OUT,
    CHECK_LOGGED_IN,
    ASSERT_LOGGED_IN,
    REFRESH_TOKEN,
    SHOULD_REDIRECT_TO_LOGIN,
    SHOULD_REDIRECT_TO,
    SET_LOGIN_SCREEN_TYPE
} from '../actionsAuth'

import { 
    ON_LOAD_PROFILE,
    ON_LOAD_PRODUCT_CONFIG,
    ON_LOAD_CONTACT_CONFIG,
    ON_LOAD_USERS,
    ON_LOAD_OTHER_USERS,
    ON_LOAD_TASKS,
    ON_LOAD_TASK_STEPS,
    ON_LOAD_TASK_DETAILS,
    ON_LOAD_SALES,
    ON_LOAD_PUBLIC_SALES,
    ON_LOAD_CONTACT,
    ON_LOAD_TAGS_CONFIG,
    ON_LOAD_OLD_CONTACTS,
    ON_LOAD_DASHBOARD,
    ON_LOAD_ANNOUNCEMENTS,
    ON_LOAD_EVENT,
    ON_LOAD_EDITION,
    ON_LOAD_MAIL,
    ON_LOAD_HOLIDAYS,
    ON_LOAD_NEWSLETTER,
    ON_LOAD_AVAILABLE_NEWSLETTERS,
    ON_LOAD_PL_DETAILS
} from '../actionsAPI'

function data(
    state = {
        profile: {},
        productConfig: { "eventsDic": {}, "editionsDic": {}, "productsDic": {} },
        contactConfig: { "companiesDic": {}, "contactsDic": {}},
        newslettersConfig: {"languages": {}, "types": {}, "articles": {}, "confirmedNewsletters": {}},
        holidays: {},
        users: {},
        otherPlatformsUsers: {},
        tasks: {},
        taskSteps: [],
        taskDetails: {},
        sales: {},
        publicSales: {},
        openContact: {},
        openEvent: {},
        openEdition: {},
        tagsConfig: {"externalDic":{}, "internalDic":{}, "dicStructure":{}, "membersDic":{}},
        oldContactSuggestions: [],
        dashboard: {},
        announcements: [],
        openMail: {},
        availableNewsletters: []
    },
    action
) {
    switch (action.type) {
        case ON_LOAD_PL_DETAILS:
            return Object.assign({}, state, {
                plDetails: action.plDetails
            })
        case ON_LOAD_AVAILABLE_NEWSLETTERS:
            return Object.assign({}, state, {
                availableNewsletters: action.availableNewsletters
            })
        case ON_LOAD_NEWSLETTER:
            return Object.assign({}, state, {
                newslettersConfig: action.newslettersConfig
            })
        case ON_LOAD_ANNOUNCEMENTS:
            return Object.assign({}, state, {
                announcements: action.announcements
            })
        case ON_LOAD_DASHBOARD:
            return Object.assign({}, state, {
                dashboard: action.dashboard
            })
        case ON_LOAD_PROFILE:
            return Object.assign({}, state, {
                profile: action.profile
            })
        case ON_LOAD_HOLIDAYS:
            return Object.assign({}, state, {
                holidays: action.holidays
            })
        case ON_LOAD_PRODUCT_CONFIG:
            return Object.assign({}, state, {
                productConfig: action.productConfig
            })
        case ON_LOAD_CONTACT_CONFIG:
            return Object.assign({}, state, {
                contactConfig: action.contactConfig
            })
        case ON_LOAD_USERS:
            return Object.assign({}, state, {
                users: action.users
            })
        case ON_LOAD_OTHER_USERS:
            return Object.assign({}, state, {
                otherPlatformsUsers: action.otherPlatformsUsers
            })
        case ON_LOAD_TASKS:
            return Object.assign({}, state, {
                tasks: action.tasks
            })
        case ON_LOAD_TASK_STEPS:
            return Object.assign({}, state, {
                taskSteps: action.taskSteps
            })
        case ON_LOAD_TASK_DETAILS:
            return Object.assign({}, state, {
                taskDetails: action.taskDetails
            })
        case ON_LOAD_SALES:
            return Object.assign({}, state, {
                sales: action.sales
            })
        case ON_LOAD_PUBLIC_SALES:
            return Object.assign({}, state, {
                publicSales: action.publicSales
            })
        case ON_LOAD_CONTACT:
            return Object.assign({}, state, {
                openContact: action.openContact
            })
        case ON_LOAD_EVENT:
            return Object.assign({}, state, {
                openEvent: action.openEvent
            })
        case ON_LOAD_EDITION:
            return Object.assign({}, state, {
                openEdition: action.openEdition
            })
        case ON_LOAD_TAGS_CONFIG:
            return Object.assign({}, state, {
                tagsConfig: action.tagsConfig
            })
        case ON_LOAD_OLD_CONTACTS:
            return Object.assign({}, state, {
                oldContactSuggestions: action.oldContactSuggestions
            })
        case ON_LOAD_MAIL:
            return Object.assign({}, state, {
                openMail: action.openMail
            })
        default:
            return state
    }
}

function auth(
  state = {
    auth: null,
    username: "",
    loggedIn: false,
    shouldRedirectTo: null
  },
  action
) {
    const cookies = new Cookies();
    switch (action.type) {
        case SHOULD_REDIRECT_TO_LOGIN:
            return Object.assign({}, state, {
                shouldRedirectTo: '/login'
            })    
        case SHOULD_REDIRECT_TO:
            return Object.assign({}, state, {
                shouldRedirectTo: action.to
            })    
        case CHECK_LOGGED_IN:
            var loggedIn = false
            var vauth = cookies.get("sysauth")
            if ((vauth !== undefined) && (vauth !== null))
            {
                var at = vauth.access_token
                if ((at) && (at.length > 5) && (at !== undefined) && (at !== null))
                  loggedIn = true
            }
                
            return Object.assign({}, state, {
                loggedIn: loggedIn,
                username: cookies.get("sysusername"),
                //auth: vauth,
            })

        case ASSERT_LOGGED_IN:
            var loggedIn = false
            var vauth = cookies.get("sysauth")
            if ((vauth !== undefined) && (vauth !== null))
            {
                var at = vauth.access_token
                if ((at) && (at.length > 5) && (at !== undefined) && (at !== null))
                    loggedIn = true
            }
                
            return Object.assign({}, state, {
                loggedIn: loggedIn,
                username: cookies.get("sysusername"),
                shouldRedirectTo: loggedIn ? null : '/login'
                //auth: vauth,
            })

        case LOGGED_IN:
            cookies.set('sysauth', action.auth, { path: '/' })
            cookies.set('sysusername', action.username, { path: '/' })
            
            return Object.assign({}, state, {
                loggedIn: true,
                username: action.username,
                auth: action.auth,
                shouldRedirectTo: null
            })
        case REFRESH_TOKEN:
            cookies.set('sysauth', action.auth, { path: '/' })
            
            return Object.assign({}, state, {
                auth: action.auth,
                shouldRedirectTo: null
            })
        
        case LOGIN_FAILED:
            cookies.set('sysauth', null, { path: '/' })
            cookies.set('sysusername', "", { path: '/' })
            return Object.assign({}, state, {
                loggedIn: false,
                username: "",
                auth: null,
                shouldRedirectTo: '/login'
            })
        case LOG_OUT:
            cookies.set('sysauth', null, { path: '/' })
            cookies.set('sysusername', "", { path: '/' })
            return Object.assign({}, state, {
                loggedIn: false,
                username: "",
                auth: null,
                profile: {}
            })
        default:
            return state
    }
}

function interfaceParams(
    state = {
        loading: false,
        loadingProgress: null,
        notificationHeader: null,
        notificationContent: null,
        notificationClass: null,
        loginScreenType: 'login',
        editingEventId: null,
        editingEventEditionId: null,
        editingProductId: null,
        editingContactId: null,
        editingHolidayId: null,
        importingContactId: null,
        selectedContactId: null,
        selectedCompanyId: null,
        editingCompanyId: null,
        editingUserId: null,
        editingTaskId: null,
        editingSaleId: null,
        editingWebSaleId: null,
        editingTagId: null,
        selectedTagFilter: null,
        browsingEventId: null,
        browsingEditionId: null,
        invoiceViewerShown: false,
        sendingMailParams: null,
        viewingMailId: null,
        inlineMessage: "",
        liveStream: "",
        stripeRedirect: "",
        editingNewsletterArticleId: null,
        editingNewsletterTypeId: null,
        editingNewsletterId: null,
        plEditionId: null

    },
    action
) {
    switch (action.type) {
        case SET_LOGIN_SCREEN_TYPE:
            return Object.assign({}, state, {
                loginScreenType: action.screenType
            })
        case LOADING:
            if (action.isLoading)
                    return Object.assign({}, state, {
                        notificationHeader: null,
                        notificationContent: null,
                        notificationClass: null,
                        loading: action.isLoading,
                        loadingProgress: action.isLoading ? action.loadingProgress : null
                    })
                else
                    return Object.assign({}, state, {
                        loading: action.isLoading,
                        loadingProgress: action.isLoading ? action.loadingProgress : null
                    })
        case SET_NOTIFICATION:
            return Object.assign({}, state, {
                notificationHeader: action.header,
                notificationContent: action.content,
                notificationClass: action.nclass,
            })
        case MODIFY_INTERFACE_PARAM:
            var cs = Object.assign({}, state, {})
            cs[action.paramName] = action.paramValue
            return cs
        default:
            return state
  }

}

export default combineReducers({
    auth,
    interfaceParams,
    data
})