import { Component } from 'react';
import { connect } from 'react-redux'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Select from 'react-select';
import Form from 'react-bootstrap/Form';


import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { MODIFY_INTERFACE_PARAM } from '../redux/actions';
import { apiRequest, apiRequestWithFiles, getAttachmentForSale, getInvoiceForWebSale, getOfferForSale } from '../redux/actionsAPI';
import {LABEL_QNOTE, DEFAULT_VALUE_LONG_NOTE} from "../econf/cnf"

import moment from "moment"
import { dynamicSortMultiple, oValues, productValueLabel, valueLabel } from './_tools';

class WebSaleEditModal extends Component {
    numFields = [["agreedPrice", "HTVA"]]
    textFields = [["oneTimeProduct", "Product customisation"], ["note",LABEL_QNOTE], ["invoiceReceival", "To whom the invoice is addressed (pdf)"], [ "invoiceRef", "Reference"], [ "invoiceCRef", "Client reference (PO)"], ['invoiceMemo', 'Accounting note']]
    uDate = new Date ()
    state =  Object.assign({revertedBy:[], revertedOf:-1, deleteClientAttachment:false, deleteAttachment:false, wereChanges:false, taskID: null, selectedFile: null, selectedClientFile: null, hasFile: null, hasClientFile: null, isHidden:false, invoiceMissingField:null, initialBy: null, products: [], contact:{value:0, label: '----'}, contactDetails:null, contactCompany: null, contactCompanySel:{value:0, label: '----'}, 
                            user:{value:0, label: '----'}, longNote: null, invoiceChecked: false, invoiceSent: false, invoicePaid: false, cashPaid:false, willCashPaid: false, product:{value:0, label: '----'},
                            transactionDate: null, transactionDateT: null, invoiceDate: null, invoiceDateT: null, membershipStartDate:null, membershipStartDateT: null}, ...this.textFields.map ((el) => ({ [el[0]]:"" })).concat (...this.numFields.map ((el) => ({ [el[0]]:"" })))) 
    
    productsList = []
    contactsList = []
    companiesList = []
    usersList = []

    numberWithSpaces(x) {
        if (!x)
            return x
        if (Math.abs (x) < 0.01)
            return 0
        
        var parts = x.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, "\u00A0");
        if ((parts.length > 1) && (parts[1].length > 2))
            parts[1] = parts[1].substr (0,2)
        return parts.join(".");
    }
    
    componentDidMount() {
        if (this.props.interface.editingWebSaleId > 0)
        {
            console.log (123, this.props.interface.editingWebSaleId, this.props.publicSales[this.props.interface.editingWebSaleId])
            this.setState (this.props.publicSales[this.props.interface.editingWebSaleId])
        }
    }

    handleInputChange = e => {
        this.setState({ [e.target.name]: e.target.value, wereChanges:true });
    };

    handleSelectChange = (e, inp) => {
        this.setState({ [inp.name]: e, wereChanges:true });
    };

    handleCBChange = e => {
        this.setState({ [e.target.name]: !this.state[e.target.name], wereChanges:true });
    };

    

    render () {
        console.log (this.state)
        var invoiceCaption = "Invoice"
        var that = this
        var prod = that.state.prodDetails ? productValueLabel (parseInt(that.state.prodDetails.split('regStripe')[1]), that.props.productConfig) : {}
        var pContact = that.state.contact ? that.state.contact : {}

        var textFields = [["billingAddr", "Street and house"], ["city", "City"], ["zipCode", "Postal code"], ['country', "Country"], ['billingVAT', "VAT Number"]]
        var personTextFields = [["firstName", "First Name"],["lastName", "Last Name"], ["email", "email"], ["jobTitle", "Position"], ["companyName", "Company"]]
        
        
        return (
            <Modal size='xl' className='noPrint' scrollable={true} show={true} onHide={(e)=>{that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingWebSaleId", "paramValue": null})}}>
                <Modal.Header closeButton>
                    <Modal.Title>{(that.props.interface.editingWebSaleId > 0) ? ("Web Transaction " + that.props.interface.editingWebSaleId):"Create web transaction"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form method="post" onSubmit={(e)=>{e.preventDefault}}>
                        <Row>
                            <Col md={4}>
                                <div className="control mb-3">
                                    <label className="form-label">Transaction date:</label>
                                    <div>{that.state.createdAt}</div>
                                </div>
                                <div className="control mb-3">
                                    <label className="form-label">Product:</label>
                                    <div>{prod.label}</div>
                                </div>

                                <div className="control mb-3">
                                    <label className="form-label">Amount HTVA:</label>
                                    <div>{that.numberWithSpaces(that.state.amount)}</div>
                                </div>

                                {[["isPaid", "Paid"], ["isReverted", "Refunded"], ["invoiceSent", "Invoice Sent"]].map (function (f, ic) {
                                    return <div className="control mb-3" key={"sf" + ic}>
                                        <label className="form-label">{f[1]}: {(that.state[f[0]]) ? <i className={"fs-4 bi-check2"}></i>:<i className={"fs-4 bi-x"}></i> }</label>

                                    </div>
                                })}
                            </Col>
                            <Col md={4}>
                                {textFields.map (function (fn, ic) {
                                    return <div className="control mb-3" key={"tf" + ic}>
                                        <label htmlFor={fn[0]} className="form-label">{fn[1]}</label>
                                        <input
                                            className="form-control"
                                            name={fn[0]}
                                            onChange={(e)=>{that.setState ({contact: Object.assign({}, that.state.contact, {[fn[0]]:e.target.value})})}}
                                            value={that.state.contact[fn[0]]}
                                            type="text"
                                        />
                                    </div>
                                })}                                
                            </Col>
                            <Col md={4}>
                                {that.state.contacts ? that.state.contacts.map (function (cont, pCnt) {
                                    return <div className='mb-3' key={"c" + pCnt}>
                                        <h4>Person {pCnt + 1}</h4>
                                        {personTextFields.map (function (fn, ic) {
                                            return <div className="control" key={"ptf" + pCnt + '_' + ic}>
                                                <label htmlFor={fn[0]+pCnt} className="form-label">{fn[1]}</label>
                                                <input
                                                    className="form-control"
                                                    name={fn[0]+pCnt}
                                                    onChange={(e)=>{ 
                                                        var nst = [...that.state.contacts]; 
                                                        nst[pCnt] = Object.assign({}, cont, { [fn[0]]:e.target.value })
                                                        that.setState ({contacts: nst})
                                                    }}
                                                    value={cont[fn[0]]}
                                                    type="text"
                                                />
                                        </div>
                                        })}
                                    </div>
                                }): null}

                                
                            </Col>
                        </Row>   
                    </form>    
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={(e)=>{that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingWebSaleId", "paramValue": null})}}>
                        Close
                    </Button>
                    {(this.props.profile.isExAdmin || (this.props.profile.isWebSales)) && (!that.state.isReverted) && (!that.state.invoiceSent) ? <Button variant="primary" onClick={(e)=>{ that.props.dispatch (apiRequest({'endpoint': 'editWebSale', "saleId": that.props.interface.editingWebSaleId, "newState":that.state}, {type:MODIFY_INTERFACE_PARAM, "paramName": "editingWebSaleId", "paramValue": null})) }}>
                            Update
                        </Button>:null}
                </Modal.Footer>
            </Modal>
        );
    }
}

function mapStateToProps(state) {
    return {
        interface: state.interfaceParams,
        publicSales: state.data.publicSales,
        isAdmin: state.data.profile.isAdmin,
        isExAdmin: state.data.profile.isExAdmin,
        productConfig: state.data.productConfig,
        profile: state.data.profile
    }
}

export default connect(mapStateToProps)(WebSaleEditModal)