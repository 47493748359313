import { Component } from "react";
import { connect } from 'react-redux'
import Table from 'react-bootstrap/Table';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Select, { NonceProvider } from 'react-select';


import { MODIFY_INTERFACE_PARAM } from "../../redux/actions";
import Button from "react-bootstrap/esm/Button";

import moment from "moment"
import {LABEL_EVENT, LABEL_QNOTE} from "../../econf/cnf"
import { dynamicSortMultiple, firstSortedValue, oValues, productValueLabel } from "../_tools";

class BDMDashboard extends Component {
    state = {
        "productFilter": "",
        "eventFilter": {value:0, label:"Select " + LABEL_EVENT},
        "editionFilter": {value:0, label:"Select edition"},
    }

    numberWithSpaces(x) {
        if (!x)
            return x
        if (Math.abs (x) < 0.01)
            return 0
        
        var parts = x.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, "\u00A0");
        return parts.join(".");
    }

    setEdition (eID)
    {
        if (eID in this.props.productConfig.editionsDic)
        {
            var cEdition = this.props.productConfig.editionsDic[eID]
            this.setState({ productFilter: cEdition.shortName, editionFilter: {value:cEdition.id, label:cEdition.subname} });
            return
        }
        this.setState({ productFilter: "" });
    }

    handleSelectChange = (e, inp) => {
        this.setState({ [inp.name]: e });
        if (inp.name === "eventFilter")
        {
            this.setState({ productFilter: "", editionFilter: {value:0, label:"Select edition"}});
            if (e.value in this.props.productConfig.eventsDic)
            {
                var cEvent = this.props.productConfig.eventsDic[e.value]
                if (cEvent.idEditions.length > 0)
                {
                    for (var ed of cEvent.idEditions)
                        if (this.props.productConfig.editionsDic[ed].isCurrent)
                        {
                            this.setEdition (ed)
                            break
                        }
                }
            }
        }
        if (inp.name === "editionFilter")
        {
            this.setEdition (e.value)
        }
    };

    handleInputChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    componentDidMount() {
        
    }

    componentDidUpdate () {
        if ((this.state.eventFilter.value === 0) && (Object.keys(this.props.productConfig.eventsDic).length > 0))
        {
            var d = firstSortedValue (this.props.productConfig.eventsDic, '*name')
            this.setState({ eventFilter: {value:d.id, label:d.name}, productFilter: "", editionFilter: {value:0, label:"Select edition"}});
            for (var ed of d.idEditions)
                if (this.props.productConfig.editionsDic[ed].isCurrent)
                {
                    this.setEdition (ed)
                    break
                }
        }
    }

    render () {
        var that = this

        var salesUpdateLines = null
        var totalSales = 0
        if (this.props.sales && (Object.keys(this.props.sales).length > 0) && (that.state.productFilter.length >= 5))
        {
            var seenCompanies = []
            var companyToTransactions = {}
            for (var cs of oValues(this.props.sales))
            {
                var hasSelProduct = false //(p.oneTimeProduct && ( p.oneTimeProduct.toLowerCase().includes (that.state.productFilter.toLowerCase())) )
                for (var pp of cs.products)
                    hasSelProduct = hasSelProduct || ( productValueLabel(pp.idProduct, that.props.productConfig).label.toLowerCase().includes (that.state.productFilter.toLowerCase()) )
                if ((!hasSelProduct))
                    continue

                var cName = (cs.idCcompany > 0) ? that.props.contactConfig.companiesDic[cs.idCcompany].name : ""
                if (!(cName in companyToTransactions))
                {
                    seenCompanies.push (cName)
                    companyToTransactions[cName] = []
                }
                for (var pp of cs.products)
                    if ( productValueLabel(pp.idProduct, that.props.productConfig).label.toLowerCase().includes (that.state.productFilter.toLowerCase()) )
                        companyToTransactions[cName].push ({ sale: cs, pp: pp })
            }

            seenCompanies.sort ()
            var ttSales = []
            for (var cName of seenCompanies)
                ttSales.push (...companyToTransactions[cName])

            var lCompany = null
            var lContact = null
            
            salesUpdateLines = ttSales.map (function(csi, cnt) {
                var cComp = (csi.sale.idCompany > 0) ? that.props.contactConfig.companiesDic [csi.sale.idCompany] : {}
                var cName = cComp.name
                var cContact = that.props.contactConfig.contactsDic[csi.sale.idContact]
                var res = <tr className="BDMDashBoardLine" key={"su" + cnt} onClick={(e)=>{e.stopPropagation(); that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingSaleId", "paramValue": csi.sale.id})}}>
                    <td>{(lCompany === cName) ? null:<Button className="btn-primary" onClick={(e)=>{e.stopPropagation (); that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "selectedContactId", "paramValue": csi.sale.idContact}); that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "selectedCompanyId", "paramValue": cComp.id}); that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingSaleId", "paramValue": 0})}}>+&nbsp;Sale</Button>}</td>
                    <td>{(lCompany === cName) ? null:<a onClick={(e)=>{e.stopPropagation(); that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingCompanyId", "paramValue": cComp.id})}}>{cName}</a>}</td>
                    <td>{((!cContact) || (lContact === cContact.label)) ? null:<a onClick={(e)=>{e.stopPropagation(); that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingContactId", "paramValue": csi.sale.idContact})}}>{cContact.fullName}</a>}</td>
                    <td>{productValueLabel(csi.pp.idProduct, that.props.productConfig).sublabel}</td>
                    <td>{that.numberWithSpaces(csi.pp.agreedPrice)}</td>
                    <td>{(csi.sale.invoicePaid || csi.sale.cashPaid) ? <i className={"fs-4 bi-check2"}></i>:(csi.willCashPaid ? <i className={"fs-4 bi-hourglass"}></i>:null) }</td>
                    <td>{csi.sale.note}</td>
                </tr>
                lCompany = cName
                lContact = cContact ? cContact.label : "nc"
                totalSales += csi.pp.agreedPrice
                return res
                
            })
        }
        
        
        return <div className="BDMDashboard noPrint">
            
            <div className={"mt-3 salesUpdate" + (that.props.interface.plEditionId ? " noPrint" : " doPrint ")} id="salesUpdate">
                <h3>Sales state - {moment().format("DD/MM/YYYY")} {this.state.productFilter.length > 0 ? (' - "' + this.state.productFilter + '"'):""}</h3>
                <div className="noPrint">
                    Filter: 
                    <Select 
                        className="d-inline-block ms-3"
                        menuPosition="fixed"
                        name="eventFilter"
                        onChange={that.handleSelectChange}
                        value={that.state["eventFilter"]}
                        options={[{value:0, label:"Select event"}].concat (oValues (this.props.productConfig.eventsDic).sort (dynamicSortMultiple('name')).map (function (d) { return {value:d.id, label:d.name} })) }
                    />
                    <Select 
                        className="d-inline-block ms-3"
                        menuPosition="fixed"
                        name="editionFilter"
                        onChange={that.handleSelectChange}
                        value={that.state["editionFilter"]}
                        options={[{value:0, label:"Select edition"}].concat (oValues (this.props.productConfig.editionsDic).filter (function (d) { return d.idEvent === that.state.eventFilter.value } ).sort (dynamicSortMultiple('subname')).map (function (d) { return {value:d.id, label:d.subname} })) }
                    />
                    <input
                        className="form-control me-3 d-inline-block d-none"
                        name="productFilter"
                        onChange={that.handleInputChange}
                        value={that.state["productFilter"]}
                        type="text"
                        placeholder="Product"
                    />
                    
                </div>
                {(salesUpdateLines) && (that.state.productFilter.length >= 5) ? <Table responsive className="table-hover tasksTable">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Company</th>
                            <th>Contact</th>
                            <th>Product</th>
                            <th>Price</th>
                            <th>Paid</th>
                            <th>{LABEL_QNOTE}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {that.props.profile.isExAdmin && false ? <tr className="totalLine">
                            <td>TOTAL</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>{that.numberWithSpaces(totalSales)}</td>
                        </tr>:null}
                        {salesUpdateLines}
                    </tbody>
                </Table>:<h5 className="mt-3">Please select the event and edition above to see the update information</h5>}
            </div>
        </div>
    }
}

function mapStateToProps(state) {
    return {
        interface: state.interfaceParams,
        isAdmin: state.data.profile.isAdmin,
        profile: state.data.profile,
        dashboard: state.data.dashboard,
        productConfig: state.data.productConfig,
        contactConfig: state.data.contactConfig,
        sales: state.data.sales
    }
}

export default connect(mapStateToProps)(BDMDashboard)