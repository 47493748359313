import { Component } from 'react';
import { connect } from 'react-redux'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Creatable from 'react-select/creatable';


import { MODIFY_INTERFACE_PARAM } from '../redux/actions';
import { apiRequest } from '../redux/actionsAPI';
import { dynamicSortMultiple, oValues } from './_tools';


class CompanyEditModal extends Component {
    textFields = [["name", "Name"], ["shortName", "short name to show in tables"], ["address", "Address"], ["zipCode", "ZIP code"],["city", "City"],["country","Country"],["website","Website"],["social", "Social networks"], ["vat", "VAT"], ["accountingEmail", "Special e-mail for invoices"]]
    cbFields = [["inBelgiumButWithoutVAT", "Is belgium or EU company, but doesn't have a VAT number"], ["isOutOfEU", "Is out of EU. Check ONLY if the company is not from EU and not liable to VAT"]]
    state =  Object.assign({ idCompanyContacts:[], tags: [], fContact: 0, iContact: 0, }, ...this.textFields.map ((el) => ({ [el[0]]:"" }))) 
    
    componentDidMount() {
        var cs = {}
        if (this.props.interface.editingCompanyId in this.props.contactConfig.companiesDic)
        {
            var el = this.props.contactConfig.companiesDic[this.props.interface.editingCompanyId]
            for (var tf of this.textFields)
                cs[tf[0]] = el[tf[0]]
            for (var tf of this.cbFields)
                cs[tf[0]] = el[tf[0]]
            cs['fContact'] = el.idFavoriteContact
            cs['iContact'] = el.idInvoiceContact
            cs['tags'] = []
            for (var t of el.idTags)
                cs['tags'].push (this.props.tagsConfig.dicStructure[t])
            cs['idCompanyContacts'] = el.idContacts
        }
        this.setState (cs)
    }

    handleInputChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    handleSelectChange = (e, inp) => {
        this.setState({ [inp.name]: e });
    };

    handleCBChange = e => {
        this.setState({ [e.target.name]: !this.state[e.target.name] });
    };

    render () {
        var that = this
        //var vatRe = /^[A-Za-z]{2}\d{8,12}$/;
        //var vatIsCorrect = (that.state.vat.length < 1) || (vatRe.test (that.state.vat))
        
        var vatIsCorrect = (!that.state.vat) || (that.state.vat.length < 1) || (that.state.vat.length > 5) || (that.state.vat.toLowerCase() === 'xx')
        var companyContacts = that.state.idCompanyContacts.map (function (cid) {
            return that.props.contactConfig.contactsDic[cid]
        }).sort (dynamicSortMultiple ('user__last_name', 'user__first_name'))

        return (
            <Modal show={true} onHide={(e)=>{that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingCompanyId", "paramValue": null})}}>
                <Modal.Header closeButton className='mb-3'>
                    <Modal.Title>{(that.props.interface.editingCompanyId > 0) ? "Edit company":"Create company"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form method="post" onSubmit={(e)=>{e.preventDefault}} className='mb-3'>
                        <div className="mb-3">
                            {this.textFields.map (function (fn, ic) {
                                return <div className="control mb-3" key={"tf" + ic}>
                                <label htmlFor={fn[0]} className="form-label">{fn[1]} {((fn[0] === 'vat') && (!vatIsCorrect)) ? <span style={{color:"red"}}>Please check the correctness of the VAT format</span>:null}</label>
                                
                                {(fn[0] === 'social') ? 
                                    <textarea
                                        id={fn[0]}
                                        className="form-control"
                                        name={fn[0]}
                                        onChange={that.handleInputChange}
                                        value={that.state[fn[0]]}
                                        rows={2}
                                    />:
                                    <input
                                        id={fn[0]}
                                        className="form-control"
                                        name={fn[0]}
                                        onChange={that.handleInputChange}
                                        value={that.state[fn[0]]}
                                        type="text"
                                    />}
                            </div>
                            })}
                            {this.cbFields.map (function (fn, ic) {
                                                return <div className="control" key={"cb" + ic}>
                                                <input
                                                    id={fn[0]}
                                                    className="form-check-input"
                                                    name={fn[0]}
                                                    onChange={that.handleCBChange}
                                                    checked={that.state[fn[0]]}
                                                    type="checkbox"
                                                />
                                                <label htmlFor={fn[0]} className="form-check-label ms-2">{fn[1]}</label>
                                            </div>
                                            })}
                            {(that.props.interface.editingCompanyId > 0) ?
                                <div className="control mb-3">
                                    <label htmlFor="fContact" className="form-label">Favorite contact</label>
                                    <select 
                                        name="fContact"
                                        id="fContact"
                                        className="form-control"
                                        required
                                        onChange={that.handleInputChange}
                                        value={that.state.fContact}
                                    >
                                    <option value={0}>----------</option>
                                    {companyContacts.map (function (el) {
                                        return <option value={el.id} key={"selFContact" + el.id}>{el.fullName} </option>
                                    })}
                                </select>
                                </div>:null }
                                {(that.props.interface.editingCompanyId > 0) ?
                                <div className="control mb-3">
                                    <label htmlFor="iContact" className="form-label">Purchase contact (person signing the purchase order, if different from the contact)</label>
                                    <select 
                                        name="iContact"
                                        id="iContact"
                                        className="form-control"
                                        required
                                        onChange={that.handleInputChange}
                                        value={that.state.iContact}
                                    >
                                    <option value={0}>----------</option>
                                    {companyContacts.map (function (el) {
                                        return <option value={el.id} key={"selIContact" + el.id}>{el.fullName} </option>
                                    })}
                                </select>
                                </div>:null }
                                <div className="control mb-3">
                                    <label htmlFor="tags" className="form-label">Tags</label>
                                    <Creatable 
                                        name="tags"
                                        id="tags"
                                        onChange={that.handleSelectChange}
                                        value={that.state.tags}
                                        isMulti={true}
                                        options={oValues (that.props.tagsConfig.externalDic).sort(dynamicSortMultiple('label'))}
                                    />
                                </div>
                        </div>    
                    </form>    
                </Modal.Body>
                <Modal.Footer className='pb-3 pt-3 mb-3 mt-3'>
                <Button variant="secondary" className='mt-3' onClick={(e)=>{that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingCompanyId", "paramValue": null})}}>
                    Close
                </Button>
                <Button variant="primary" className='mt-3' disabled={!vatIsCorrect} onClick={(e)=>{ this.props.dispatch (apiRequest({'endpoint': 'editCompany', "companyId": that.props.interface.editingCompanyId, "newState":that.state}, {type:MODIFY_INTERFACE_PARAM, "paramName": "editingCompanyId", "paramValue": null})) }}>
                    Save
                </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

function mapStateToProps(state) {
    return {
        interface: state.interfaceParams,
        contactConfig: state.data.contactConfig,
        tagsConfig: state.data.tagsConfig
    }
}

export default connect(mapStateToProps)(CompanyEditModal)